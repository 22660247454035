import React, { useEffect, useState ,useContext, useRef} from 'react';
import inventarioContext from '../../../context/inventario/inventarioContext';
import { Box, Button, Divider, Grid, MenuItem, Modal, TextField, Typography, styled } from '@mui/material';
import { Search, Tune } from '@mui/icons-material';
import { BotonJCs } from '../../material';
import { RutasContext, SucursalesContext } from '../../../context';
import EnhancedTable from './Tabla';
import moduleContext from '../../../context/modules/moduleContext';
import { useContainerDimensions } from '../../../functions';
import AuthContext from '../../../context/authentication/authContext';


  // const style_input_change = {
  //   borderRadius: "8px",
  //   outline: "none",
  //   border: "1px solid #cbcbcb",
  //   marginLeft: "5px !important"
  // }
  const style_input_change = {
    paddingLeft: "5px !important",
    "& input":{
      padding: "3px 8px !important",
      fontSize: "12px",
      fontFamily: "'semibold'",
      borderRadius: "8px",
      textAlign: "end",
      maxWidth: "70px"
    }
  }

  function createData(id, nombre, sku, total, n_disponible, comprometido, existencia, entrante, search) {
    return {
      id,
      nombre,
      sku,
      total,
      n_disponible,
      comprometido,
      existencia,
      entrante,
      search
    };
  }

  const AreaButton = ({onClick}) => {
    return(<Box>
        <BotonJCs sx={{
          marginLeft: "10px",
          fontSize: "12px !important",
        }}>Exportar</BotonJCs>
        <BotonJCs sx={{
          marginLeft: "10px",
          fontSize: "12px !important",
        }}>Importar</BotonJCs>
        <BotonJCs onClick={onClick} sx={{
          marginLeft: "10px",
          background: "#3F3F3F !important",
          fontSize: "12px !important"
        }}>
          Ver Sucursales
        </BotonJCs>
    </Box>)
  }

  const MenuSeccion = ({verSucursales}) => {
    return(
        <Box className='area-modulo' sx={{borderRadius: "5px"}}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography id="titulo-modulo" sx={[{
                        paddingBottom: '0px',
                        textAlign: 'start',
                        fontWeight: "bold",
                        color: "black !important"
                    }]} variant='h6'>Inventario</Typography>
                    <Box sx={{"& p":{color: "#626262 !important", fontSize: "12px", margin: "0px !important"}}}>
                        <Box>
                            <Typography>Gestiona las cantidades de inventario de tus productos físicos.</Typography> 
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{
                    textAlign: "end",
                    "& #btn-agregar":{
                        margin: "10px !important", padding: '5px 20px !important'
                    }
                }}>
                    <AreaButton onClick={verSucursales} nombre_modulo="Productos"/>
                </Grid>
            </Grid>
            <Box sx={{paddingBottom: "15px !important"}}/>
        </Box>
    )
  }

  export default function Inventario() {
    const sucursalesContext = useContext(SucursalesContext);
    const { obtener_sucursales, sucursales, seleccionar_sucursal, sucursal } = sucursalesContext;

    const ModuleContext = useContext(moduleContext);
    const { verSucursales } = ModuleContext;

    const authContext = useContext(AuthContext);
    const { obtener_negocios } = authContext;

    // const rutasContext = useContext(RutasContext);
    // const { getRoute } = rutasContext;

    const [seleccion, setSeleccion] = useState(null)
    const [loading, setLoading] = useState(true)
    const [cambios, setCambios] = useState([])
    // const [verSucursales, setVerSucursales] = useState(false)
    const InventarioContext = useContext(inventarioContext);
    const {
      inventario,
      obtenerInventario,
      modalView,
      abrirModal,
      productos,
      total,
      existencias
    } = InventarioContext;

    const headCells = [
      {
        id: 's_name',
        disablePadding: true,
        label: 'Nombre',
      },
      {
        id: 's_variant_sku',
        disablePadding: true,
        align: "left",
        label: "SKU"
      },
      {
        id: 'i_stock',
        disablePadding: true,
        align: "right",
        label: 'Total',
      },{
        id: 'n_disponible',
        disablePadding: true,
        align: "right",
        label: 'No disponible',
      },
      {
        id: 'n_comprometido',
        disablePadding: true,
        align: "right",
        label: 'Comprometido'
      },{
        id: 'i_existencias',
        disablePadding: true,
        align: "right",
        label: 'Existencias',
      },{
        id: 'i_entrante',
        disablePadding: true,
        align: "right",
        label: 'Entrante',
      }
    ];

    useEffect(()=>{
      let id_negocio = localStorage.getItem('id_route');
      obtener_sucursales(id_negocio);

      const transform_business = async () => {
          let business = await obtener_negocios(id_negocio)
          let urlPath = `/business/${business}/logistics/inventory`
          window.history.pushState(`${business}`, 'Title', urlPath);
      }

      transform_business();
    },[])
    
    const estatus = [
      {_id: 0, s_title: "Suspendido"},
      {_id: 1, s_title: "Pendiente"},
      {_id: 2, s_title: "Activo"}
    ]

    const Options = ({options, name, id}) => {
      return(<TextField
        select
        label={name}
        onChange={e => {
          setForm({
          ...form,
          [id]: e.target.value
        })}} 
        value={form[`${id}`]} 
        id={`${id}`}
        sx={{
          "& svg, div":{ color: "black !important"},
          "& fieldset":{ borderRadius: "12px"},
          color: "black !important",
          width: "100% !important"
        }}
        SelectProps={{
          native: true,
        }}
        focused
        size='small'
      >
        <option value="" selected disabled hidden>Elegir...</option>
        {/* <option style={{color: "#bdbdbd !important"}}>Elegir item</option> */}
        {options.map((option, i) => (
        <option key={i} value={option._id} style={{color: "black !important"}}>
          {option.s_title}
        </option>
      ))}</TextField>)
    }
    const heads = [
      {view:true, size: 12, name: "Nombre", id: "s_name"},
      {view:true, size: 7,  name: "stock", id: "i_stock", type: "number"},
      {view:true, size: 7,  name: "Entrante", id: "i_entrante", type: "number"},
      {view:true, size: 7,  name: "Canales de Ventas", id: "i_sales_channels",type: "number"},
      {view:true, size: 7,  name: "Tiendas", id: "i_markets", type: "number"}
    ]
    
    const handleClose = () => abrirModal(false);
    const [form, setForm] = useState(false)
    const [rows, setRows] = useState([])
    const [rows_filter, setRowsF] = useState([])
    const [select_, setSelect_] = useState(null)
    const [new_rows, setNewRows] = useState([])
    const [total_p, setTotalP] = useState(0)
    const [original_rows, setOriginalRows] = useState([])

    
    const ref = useRef(null);
    const { width, height } = useContainerDimensions(ref)

    const calcular_existencias = (ecuacion = {
      i_stock: 0,
      n_comprometido: 0,
      n_disponibilidad: 0
    }) => {
      return (ecuacion.i_stock-(ecuacion.n_comprometido+ecuacion.n_disponibilidad))
    }

    const cambiosInventario = (_id, stock_) =>{ 
      setCambios(cambios_old =>{
        let filtrar_cambios_old = cambios_old.filter(item_old => item_old._id == stock_._id)
        if(filtrar_cambios_old?.length > 0){
          let resto_cambios_old = cambios_old.filter(item_old => item_old._id != stock_._id)
          return [...resto_cambios_old, stock_]
        }
        return [...cambios_old, stock_]
      })
    }

    const setInput = (e, _id) => {
      setRows(rows_old => {
        return rows_old.map(item_old => {
          if(item_old?._id == _id){
            cambiosInventario(_id, {
              ...item_old,
              [e.target.name]: parseInt(e.target.value),
              a_sucursales: item_old?.a_sucursales?.length > 0 ? item_old.a_sucursales.map(a_sucursal => {
                if(a_sucursal._id == select_._id){
                  return {
                    ...a_sucursal,
                    [e.target.name]: parseInt(e.target.value),
                  }
                }
                return a_sucursal
              }): []
            })
            return {
              ...item_old,
              [e.target.name]: parseInt(e.target.value),
              a_sucursales: item_old?.a_sucursales?.length > 0 ? item_old.a_sucursales.map(a_sucursal => {
                if(a_sucursal._id == select_._id){
                  return {
                    ...a_sucursal,
                    [e.target.name]: parseInt(e.target.value),
                  }
                }
                return a_sucursal
              }): []
            }
          }

          return item_old
        })
      })
    }

    useEffect(()=>{
      const Celda = ({children}) => {
        return(<Box><Typography sx={{
          fontSize: "12px !important",
          fontFamily: "semibold"
        }}>{children}</Typography></Box>)
      }

      const async_function = async () => {
        await setNewRows(rows.map((item, key_item) => {
          let stock_sucursal = item?.a_sucursales?.length > 0 ? item.a_sucursales.filter(a_sucursal => a_sucursal._id == select_._id): [],
          stock_inventory = null;
  
          if(stock_sucursal?.length > 0){
            stock_inventory = stock_sucursal[0]
          }          
          
          return createData(
            key_item,
            (<Celda>{item?.s_title ? item.s_title: ""}</Celda>),
            (<Celda>{
              item?.variante?.length > 0 ? item.variante[0]?.s_variant_sku: 
              item?.producto?.length > 0 ? item.producto[0]?.s_variant_sku: ""}
            </Celda>),
            (<Box sx={style_input_change}><TextField name="i_stock" type='number' onChange={e=>setInput(e, item._id)}
              value={stock_inventory?.i_stock ? stock_inventory.i_stock: 0}>
            </TextField></Box>),
            (<Box sx={style_input_change}><TextField name="n_disponibilidad" type='number' onChange={e=>setInput(e, item._id)}
              value={stock_inventory?.n_disponibilidad ? stock_inventory.n_disponibilidad: 0}></TextField></Box>), 
            (<Box sx={style_input_change}><TextField name="n_comprometido" type='number' onChange={e=>setInput(e, item._id)}
              value={stock_inventory?.n_comprometido ? stock_inventory.n_comprometido: 0}></TextField></Box>),
            (<Typography sx={{fontSize: "12px", fontFamily: "semibold", minWidth: "60px"}}>{calcular_existencias(item)}</Typography>),
            (<Box sx={style_input_change}><TextField type='number' onChange={e=>{}}
              value={stock_inventory?.i_entrante ? stock_inventory.i_entrante: 0}></TextField></Box>),
            item.s_title)
        }))
      }

      async_function()

    },[rows])

    useEffect(()=>{
      if(select_?._id){
        obtenerInventario(select_._id)
      }
    },[select_])

    useEffect(()=>{
      let inventarioEditado = inventario.map(producto =>{ return { ...producto, update: false}})

      setRows(inventarioEditado)
      setRowsF(inventarioEditado)
      setOriginalRows(inventarioEditado)

      let inventario__ = null
      let productos_unicos = inventario.filter(p_n => p_n.b_variantes == 0)
      setTotalP(productos_unicos.length)

      inventario__ = inventario
      if(inventario__){
        if(inventario__.length > 0){
          setLoading(false)
        }else{}
      }
    },[inventario])

    useEffect(()=>{
      if(sucursales.length){
        setSelect_(sucursales[0])
      }
    },[sucursales])

    return(<Box>
        <Modal
          open={modalView}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
              <Grid container>
              <Grid item xs={8}>
                  <Typography
                    sx={{color: "black !important"}}
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >Nueva Existencia</Typography>
              </Grid>
              <Grid item xs={4} sx={{textAlign: "right"}}>
                  <Button sx={{
                    background: "#091621 !important",
                    fontSize: "13px",
                    borderRadius: "25px",
                    textTransform: "none"
                  }} variant="contained" onClick={() => {
                    if(form){
                      if(!form?.s_name) return console.log("Se requiere nombre")
                      if(!form?.id_producto) return console.log("Se requiere asignar producto")
                      
                      let id_negocio = localStorage.getItem("id_route")
                      
                      // guardarInventario([{
                      //   ...form,
                      //   id_negocio: id_negocio
                      // }])
                      handleClose()
                    }
                  }}>
                  Guardar
                  </Button>
              </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={9} sx={{paddingRight: "20px"}}>
                  <Grid container sx={{
                    marginTop: "10px"
                    }}>
                      {heads ? heads.length > 0 ?
                          heads.map((head, i_head) => {
                          if(head.view){
                          return(<Grid key={i_head} sx={{height: "fit-content", "& input": {color: "black !important"}}} item xs={head?.size ? head.size: 4}>
                              {head?.render ? head.render: (<TextField required type={head?.type ? head.type: "text"} 
                                sx={{
                                  color: "black !important",
                                  width: "100% !important",
                                  margin: "10px 0px",
                                  "& fieldset": { borderRadius: "12px"}
                                }} onChange={e => {
                              setForm({
                              ...form,
                              [head.id]: e.target.value
                              })}} value={form[`${head.id}`]} id={`${head.id}`} label={`${head.name}`} size="small" variant="outlined">
                              </TextField>)}
                          </Grid>)
                          }
                          }): null: null
                      }
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Box sx={{margin: "20px 0px"}}><Options id="i_status" name="Estatus" options={estatus}/></Box>
                  <Box sx={{margin: "20px 0px"}}><Options id="id_producto" name="Producto" options={productos}/></Box>
                </Grid>
              </Grid>
          </Box>
        </Modal>
        <MenuSeccion verSucursales={()=>{verSucursales()}}/>
        <Box ref={ref}>
          <Box sx={{
              marginY: "20px",
              borderRadius: "8px",
              background: "white !important",
              width: "100%", 
              boxShadow: "0px 2px 5px -4px black"
          }}>
              <Grid container>
                  <Grid item xs={8}>
                      <Box sx={{
                          display: "flex",
                          alignItems: "center",
                          "& #sucursales":{
                              fontSize: "12px !important",
                              padding: "5px 20px",
                              borderRadius: "8px"
                          }
                      }}>
                          <Typography sx={{
                              marginLeft: "20px",
                              fontSize: "13px",
                              color: "#1A1E1D",
                              background: "transparent !important"
                          }}>Sucursales:</Typography>
                          <TextField
                              variant="standard" // <== changed this
                              id="sucursales"
                              name="sucuerales"
                              autoFocus
                              select
                              defaultValue={0}
                              onChange={e=>{
                                let selecto_ = e.target.value, array_selecto = sucursales[selecto_]
                                
                                setSelect_(array_selecto)
                              }}
                              sx={{
                                  marginY: "5px",
                                  marginLeft: "10px",
                                  borderRadius: "8px",
                                  background: "transparent !important",
                              }}
                              InputProps={{
                                  fontSize: "12px !important",
                                  background: "transparent !important",
                                  disableUnderline: true, // <== added this
                              }}
                          >
                            {sucursales?.length > 0? sucursales.map((sucursal, i_sucursal) => {
                              return(<MenuItem sx={{
                                color: "black !important",
                                fontSize: "12px !important",
                                padding: "5px 15px"
                              }} key={i_sucursal} value={i_sucursal}>{sucursal.s_nombre} {sucursal.s_ciudad}, {sucursal.s_pais}</MenuItem>)
                            }): (<MenuItem sx={{
                                  color: "black !important",
                                  fontSize: "12px !important"
                              }} key="0" value={0} selected>Sin Sucursales</MenuItem>)}
                          </TextField>
                      </Box>
                  </Grid>
                  <Grid item xs={4} sx={{display: "flex", justifyContent: "end"}}>
                      <Box sx={{margin: "6px"}}>
                          <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>
                            {new_rows?.length ? new_rows.length: 0}
                          </Typography>
                          <Typography sx={{
                              fontSize: "12px",
                              color: "#626262"
                          }}>
                              SKU
                          </Typography>
                      </Box>
                      <Box sx={{margin: "6px"}}>
                          <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>
                            {total ? total: 0}  
                          </Typography>
                          <Typography sx={{
                              fontSize: "12px",
                              color: "#626262"
                          }}>
                              Total
                          </Typography>
                      </Box>
                      <Box sx={{margin: "6px"}}>
                          <Typography sx={{fontSize: "12px", fontWeight: "bold"}}>
                              {existencias ? existencias: 0}
                          </Typography>
                          <Typography sx={{
                              fontSize: "12px",
                              color: "#626262"
                          }}>
                              Existencias
                          </Typography>
                      </Box>
                      <Box sx={{margin: "6px", display: "flex", marginRight: "10px", alignItems: "center"}}>
                          <Box sx={{
                              marginX: "5px",
                              background: "#EBEBEB",
                              width: "30px",
                              height: "30px",
                              display: "grid",
                              justifyContent: "center",
                              alignContent: "center",
                              borderRadius: "8px",
                              cursor: "pointer"
                          }}>
                              <Search sx={{fontSize: "16px"}}/>
                          </Box>
                          <Box sx={{
                              marginX: "5px",
                              background: "#EBEBEB",
                              width: "30px",
                              height: "30px",
                              display: "grid",
                              justifyContent: "center",
                              alignContent: "center",
                              borderRadius: "8px",
                              cursor: "pointer"
                          }}>
                              <Tune sx={{fontSize: "16px"}}/>
                          </Box>
                      </Box>
                  </Grid>
              </Grid>
              <Divider color="#EBEBEB"/>
              <Box sx={{"& #filtro":{fontSize: "12px !important"}}}>
                  <TextField
                      variant="standard" // <== changed this
                      name="sucuerales"
                      autoFocus
                      sx={{
                          marginLeft: "20px",
                          marginY: "5px",
                          borderRadius: "8px",
                          background: "transparent !important",
                      }}
                      InputProps={{
                          fontSize: "12px !important",
                          background: "transparent !important",
                          disableUnderline: true, // <== added this
                      }}
                      
                      id="filtro"
                      select
                      size='small'
                      defaultValue="1"
                  >
                      <MenuItem sx={{
                          color: "black !important",
                          fontSize: "12px !important"
                      }} key="1" value={1} selected>Todos</MenuItem>
                  </TextField>
              </Box>
          </Box> 
      </Box>
      <EnhancedTable
        hx={height}
        refreshSave={()=> setCambios([])}
        loading={loading}
        rows={new_rows}
        headCells={headCells}
        cambios={cambios}
      />      
      </Box>)
    // }
  }


  const styleModal = {
    position: 'absolute',
    color: "black !important",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "40%",
    height: "60vh",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    borderRadius: "10px"
  };