import React, {useContext, useEffect, useState} from 'react';
import alertsContext from '../../context/alerts/alertsContext';
import AuthContext from '../../context/authentication/authContext';
import {RutasContext} from '../../context';
import {Link} from "react-router-dom";
import {
    TextField,
    InputAdornment, 
    IconButton,
    Box,
    Container,
    Button,
    Card,
    Grid,
    styled,
    Typography
} from '@mui/material';
import {ArrowForward, Visibility, VisibilityOff} from '@mui/icons-material'
import imgUrl from '../../img/logos/logo_w.png'
import imgUrl_2 from '../../img/logos/logo_w_h.png'
 
const Login = props => {
    // Extraer los valores del context
    const alertContext = useContext(alertsContext);
    const { alert, mostrarAlerta } = alertContext;

    // const rutasContext = useContext(RutasContext);
    // const { getRoute } = rutasContext;

    //  Extraer los valores del autenticacion
    const authContext = useContext(AuthContext);
    const { mensaje, login, autenticado, validarSesion, obtener_negocios } = authContext;

    useEffect(()=>{
        // let id_route = localStorage.getItem("id_route")
        // let route = getRoute(id_route)
        
        if(autenticado) props.history.push(`/business/ok`)
        if(mensaje){
            mostrarAlerta(mensaje.msg, 'alerta-error')
            return;
        }
    }, [mensaje, autenticado, props.history])

    useEffect(()=>{
        const token = localStorage.getItem('token');
        let id_route = localStorage.getItem("id_route")
        let route = obtener_negocios(id_route)
        
        if(token) validarSesion(route)

    },[])

    // State para iniciar sesión
    const [user, saveUser] = useState({
        email:'',
        password:'',
        showPassword: false,
    })

    const {email, password} = user;
    const onChange = e => {
        saveUser({
            ...user,
            [e.target.name] : e.target.value
        })
    }

    const handleClickShowPassword = () => {
        saveUser({
          ...user,
          showPassword: !user.showPassword,
        });
    };

    const handleMouseDownPassword = (e) => { e.preventDefault() };
    
    const onSubmit = e =>{
        e.preventDefault();
        // Validar que no haya campos vacios
        if(email.trim() === '' || password.trim() === ''){
            mostrarAlerta(
                `${email.trim() === '' ? `El usuario ${password.trim() === '' ? "y la contraseña son campos requeridos." : " es requerido."}` : 
                    password.trim() === '' ? "La contraseña es requerida." : ""
                }
                `, 
            "alerta-error")

            return;
        }

        login({ email, password });
    }

    return(<Grid container sx={{height: '100vh', alignContent: 'center', background: process.env.REACT_APP_STORE != "Lavteg" ? '#121212': "var(--dark) !important"}}>
        <Grid item xs={6} sx={{padding: '50px'}}>
            <Box sx={{display: 'grid', justifyItems: "center"}}>
                <Logo src={process.env.REACT_APP_STORE != "Lavteg" ? imgUrl_2: imgUrl}/>
            </Box>
            {process.env.REACT_APP_STORE != "Lavteg" ? null: (
                <Box>
                    <Typography sx={{
                        padding: '0px 10%',
                        marginTop: '30px',
                        textAlign: "justify",
                        textJustify: "inter-word"
                    }}>
                        Para emprendedores y empresas, desde startups hasta las más grandes,
                        desarrolla, gestiona y escala de manera completa todas las áreas de
                        tu empresa a través de nuestra metodología, aplicaciones, big data y
                        automatización.
                    </Typography>
                </Box>
            )}
        </Grid>
        <Grid item xs={6} sx={{paddingRight: '50px'}}>
            <Box sx={{
                display: 'grid',
                alignContent: 'center'
            }}>
                <SubmitForm onSubmit={onSubmit}>
                    <TextField
                        sx={{marginBottom: '20px'}}
                        className='field-login input-login' 
                        id="email"
                        type="email"
                        name="email"
                        label="Correo electrónico"
                        value={email}
                        onChange={onChange}
                        variant="outlined" fullWidth
                    />
                    <TextField
                        id="password"
                        name="password"
                        value={password}
                        onChange={onChange}
                        variant='outlined'
                        label="Contraseña"
                        className='input-login'
                        type="password"
                        fullWidth
                        sx={{ marginBottom: '20px', color: "black !important" }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {user.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <Button type='submit' sx={{
                        background: process.env.REACT_APP_STORE != "Lavteg" ? '#121212' :'var(--gris2)',
                        "&:hover":{
                            background: process.env.REACT_APP_STORE != "Lavteg" ? '#272727' :'var(--btn-hover)'
                        },
                        padding:'10px 0px',
                        textTransform: "none",
                        color:'white',
                        fontFamily:`Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';`
                    }}  variant="contained" fullWidth>Iniciar Sesión</Button>
                    <Box sx={{
                        display: "flex",
                        marginTop: "10px",
                        alignItems: "center",
                        "& p, svg":{
                            color: "#004ecc !important"
                        }
                    }}>
                        <Typography onClick={crear_nuevo_usuario} sx={{cursor: "pointer", fontSize: "14px", margin: "0px !important"}}>Registrarse</Typography>
                        <ArrowForward onClick={crear_nuevo_usuario} sx={{cursor: "pointer", fontSize: "15px", marginLeft: "4px"}}/>
                    </Box>
                    {alert ? (<Box>
                        <TextAlert>{alert.msg}</TextAlert>
                    </Box>):null}
                </SubmitForm>
            </Box>
        </Grid>
    </Grid>)
}

const crear_nuevo_usuario = () => {
    window.location.replace("/new-account");
}

const Logo = styled('img')({
    width: '80% !important',
    maxWidth: '400px'
})

const SubmitForm = styled('form')({
    background: 'white !important',
    padding: '50px !important',
    borderRadius: '5px !important'
})

const TextAlert = styled('p')({
    background: "#ffc1c1!important",
    padding: "10px!important",
    margin: "15px 0px !important",
    borderRadius: "5px!important",
    color: "black",
    textAlign: "center"
})

export default Login;