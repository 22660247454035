import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { Grid, Skeleton } from '@mui/material';
import { BotonJC } from '../../material';
import inventarioContext from '../../../context/inventario/inventarioContext';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected, 
    rowCount,
    onRequestSort,
    headCells,
    cambios = []
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  if(cambios?.length > 0){

      return null
  }else{
    return (
      <TableHead sx={{
        bgcolor: "rgb(250, 250, 250) !important",
        "& th":{
          bgcolor: "rgb(250, 250, 250) !important",
        }
      }}>
        <TableRow>
            <TableCell padding="checkbox">
              <Checkbox className="checkbox-style-table"
                style={{
                    transform: "scale(0.7)",
                }}
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </TableCell>
            {headCells.map((headCell, i_headCell) => (
              <TableCell
                sx={{
                  maxWidth: "150px !important",
                  fontSize: "12px",
                  fontFamily: "'semibold'",
                  paddingRight: headCell.label == "Existencias" ? "10px": "0px",
                  paddingY: headCell.label == "Existencias" ? "5px": "0px"
                }}
                key={i_headCell}
                // align={headCell.numeric ? 'right' : headCell?.align ? headCell.align: 'left'}
                align={headCell.align ? headCell.align : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell?.align == "right" ? headCell.label: (<TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
  }
  }

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const InventarioContext = useContext(inventarioContext);
  const { modificar_inventario } = InventarioContext;
  const { numSelected, cambios = [], refreshSave } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        minHeight: "35px !important",
        // ...(numSelected > 0 && {
        //   bgcolor: (theme) =>
        //     alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        // }),
      }}
    >
      {cambios?.length > 0 ? (  
        <Grid container>
          <Grid item xs={8} sx={{
            display: "flex",
            "& input[type='checkbox']":{
              "accent-color": "#626262 !important"
              
            }
          }}>
            <input type="checkbox" checked onClick={e=>{
              refreshSave()
            }}></input>
            <Typography
              sx={{ flex: '1 1 100%', fontSize: "12px", fontFamily: "'semibold'", marginLeft: "10px"}}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {cambios.length} editados
            </Typography>
          </Grid> 
          <Grid item xs={4} sx={{
            display: "flex",
            justifyContent: "end"
          }}>
            <Tooltip title="Guardar Ediciones">
              <input style={{
                background: "#EBEBEB",
                fontSize: "12px",
                fontFamily: "'semibold'",
                outline: "none",
                border: "none",
                borderRadius: "8px",
                padding: "0px 15px",
                height: "25px",
                width: "fit-content",
                cursor: 'pointer'
              }} onClick={()=>{
                console.log(cambios)
                modificar_inventario(cambios)
                refreshSave()
              }} type='button' value="Guardar"/>
            </Tooltip>
            <Tooltip title="Cancelar Ediciones">
              <input style={{
                background: "#EBEBEB",
                fontSize: "12px",
                fontFamily: "'semibold'",
                outline: "none",
                border: "none",
                borderRadius: "8px",
                padding: "0px 15px",
                marginLeft: "10px",
                height: "25px",
                width: "fit-content",
                cursor: 'pointer'
              }} onClick={()=>{
                refreshSave()
              }} type='button' value="Cancelar"/>
            </Tooltip>
            
          </Grid> 
        </Grid>
      ) : null}

    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({hx=0,refreshSave, rows=[], headCells=[], loading=true, cambios=[]}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('s_title');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [items, setItems] = useState([0,1,2,3,4,5,6,7,8,9]);

  useEffect(()=>{
    setRowsPerPage(100)
    setTimeout(()=>{
      setRowsPerPage(50)
    },100)
  },[rows])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  const Loading_form = (id=0, i_key) => {
    return(<TableRow
        hover
        // onClick={(event) => handleClick(event, id)}
        role="checkbox"
        // aria-checked={isItemSelected}
        tabIndex={-1}
        key={id}
        // selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell padding="checkbox">
          <Checkbox className="checkbox-style-table"
            style={{
                transform: "scale(0.7)",
            }}
            color="primary"
            // checked={isItemSelected}
            inputProps={{
              'aria-labelledby': "2938klkd",
            }}
          />
        </TableCell>
        <TableCell align='left' component="th" id={`test-${id}`} scope="row" padding="none">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="left">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
      </TableRow>)
  }

  return (
    <Box sx={{ 
      width: '100%',
      "& .checkbox-style-table svg":{
        color: "#626262 !important"
      }
    }}>
      <Paper sx={{ width: '100%', borderRadius: "8px" }}>
        {cambios?.length > 0 ? (<EnhancedTableToolbar refreshSave={refreshSave} cambios={cambios} numSelected={selected.length} />): null}
        <TableContainer
          sx={{
            borderRadius: "8px",
            height: `calc(100vh - ${hx + 280}px) !important`,
          }}
          className="scroll-table-component-side-left"
        >
          <Table
            
            stickyHeader 
            aria-label="sticky table"
            sx={{ minWidth: 350 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              cambios={cambios}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody sx={{height: "200px"}}>
                {loading ? items.map((item, i_item_key)=>(<Loading_form key={i_item_key} id={item}/>)):
                visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    sx={{
                      cursor: 'pointer',
                      "&.Mui-selected": {
                        background: "#EBEBEB !important"
                      }
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox className="checkbox-style-table"
                        style={{
                            transform: "scale(0.7)",
                        }}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      align='left'
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        maxWidth: "150px !important"
                      }}
                    >
                      {row.nombre}
                    </TableCell>
                    <TableCell align="left">{row.sku}</TableCell>
                    <TableCell sx={{
                      padding: "0px !important"
                    }}align="right">{row.total}</TableCell>
                    <TableCell sx={{
                      padding: "0px !important"
                    }} align="right">{row.n_disponible}</TableCell>
                    <TableCell sx={{
                      padding: "0px !important"
                    }} align="right">{row.comprometido}</TableCell>
                    <TableCell sx={{
                      padding: "0px !important",
                      paddingRight: "10px !important"
                    }} align="right">{row.existencia}</TableCell>
                    <TableCell sx={{
                      padding: "0px !important"
                    }} align="right">{row.entrante}</TableCell>
                  </TableRow>
                );
              })}
            
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage=""
          labelDisplayedRows={()=> {
            return ''
          }}
          SelectProps={{
            hidden: true,
            sx:{
              display: "none"
            }
          }}
          rowsPerPageOptions={[50, 100, 150, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}