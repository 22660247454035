import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { Grid, Skeleton } from '@mui/material';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{
      bgcolor: "rgb(250, 250, 250) !important",
      "& th":{
        bgcolor: "rgb(250, 250, 250) !important",
      }
    }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox className="checkbox-style-table"
            style={{
                transform: "scale(0.7)",
                color: "#626262 !important",
            }}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell, i_hedcell) => (
          <TableCell
            sx={{
              fontSize: "11px",
              fontFamily: "'semibold'"
            }}
            key={i_hedcell}
            // align={headCell.numeric ? 'right' : headCell?.align ? headCell.align: 'left'}
            align={headCell?.align ? headCell.align : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell?.align == "right" ? headCell.label: (<TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>)}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    setSelected,
    edicion_masiva = e => console.log("edición masiva")
  } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: "10px" },
        // pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: "#FFFFFF !important",
          borderRadius: "8px 8px 0px 0px",
          minHeight: "30px !important"
          // (theme) =>
          //   alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }
        ),
      }}
    >
      {numSelected > 0 ? (<Grid container>
        <Grid item xs={8}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            "& svg": {
              fontSize: "17px"
            }
          }}>
            <Checkbox onClick={e=>setSelected([])} sx={{
              fontSize: "12px"
            }} checked></Checkbox>
            <Typography
                sx={{ 
                  flex: '1 1 100%',
                  fontSize: "12px",
                  fontFamily: "semibold"
                }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                Seleccionados: {numSelected} productos
              </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ justifyContent: "end", display: "grid"}}>
          <Typography onClick={edicion_masiva} sx={{
            alignContent: "center",
            fontSize: "12px",
            fontFamily: "semibold",
            bgcolor: "#c6c6c6 !important",
            borderRadius: "8px",
            padding: "2px 10px",
            margin: "5px 0px",
            cursor: "pointer"
          }}>Edición masiva</Typography>
        </Grid>
      </Grid>
        // <div style={{
        //   display: "flex"
        // }}>
        //   <Checkbox checked></Checkbox>
        //   <Typography
        //     sx={{ 
        //       flex: '1 1 100%',
        //       fontSize: "12px"
        //     }}
        //     color="inherit"
        //     variant="subtitle1"
        //     component="div"
        //   >
        //     Seleccionados: {numSelected} productos
        //   </Typography>
        // </div>
      ) : null}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null} */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function EnhancedTable({
  rows=[],
  headCells=[],
  loading=true,
  hx=200,
  edicion_masiva=e=>console.log("edición masiva")
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('s_title');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [items, setItems] = useState([0,1,2,3,4,5,6,7,8,9]);
  

  
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(()=>{
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  },[])


  useEffect(()=>{
    if(rows?.length > 0){
        setTimeout(()=>{
            setRowsPerPage(100)
            setRowsPerPage(50)
        },100)
    }
  },[rows])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  const Loading_form = (id=0) => {
    return(<TableRow
        hover
        // onClick={(event) => handleClick(event, id)}
        role="checkbox"
        // aria-checked={isItemSelected}
        tabIndex={-1}
        key={id}
        // selected={isItemSelected}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell padding="checkbox">
          <Checkbox className="checkbox-style-table"
            style={{
                transform: "scale(0.7)",
                color: "#626262 !important",
            }}
            color="primary"
            // checked={isItemSelected}
            inputProps={{
              'aria-labelledby': "2938klkd",
            }}
          />
        </TableCell>
        <TableCell align='left' component="th" id={`test-${id}`} scope="row" padding="none">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="left">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
        <TableCell align="right">
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </TableCell>
      </TableRow>)
  }

  return (
    <Box sx={{ 
      width: '100%',
      "& .checkbox-style-table svg":{
        color: "#626262 !important"
      }
    }}>
      <Paper sx={{ width: '100%', borderRadius: "8px"}}>
      {selected.length > 0 ? (<EnhancedTableToolbar
        setSelected={setSelected}
        edicion_masiva={e=> {edicion_masiva(selected)}}
        numSelected={selected.length}
      />): null}
        <TableContainer
          sx={{
            borderRadius: "8px",
            display: "grid",
            height: `calc(100vh - ${hx + 200}px) !important`,
        }}
          className="scroll-table-component-side-left"
        >
          <Table
            stickyHeader 
            aria-label="sticky table"
            sx={{ minWidth: 350 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            {selected.length > 0 ? null:(
              <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows?.length ? rows.length: 0}
              />
            )}
            <TableBody sx={{height: "100%"}}>
                {loading ? items.map((item, i_item_)=>(<Box key={i_item_}><Loading_form id={item}/></Box>)):
                visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{
                      cursor: 'pointer',
                      "&.Mui-selected": {
                        background: "#EBEBEB !important"
                      }
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox className="checkbox-style-table"
                        style={{
                            transform: "scale(0.7)",
                            color: "#626262 !important",
                        }}
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{fontSize: "12px"}} align='left' component="th" id={labelId} scope="row" padding="none">
                      {row.s_title}
                    </TableCell>
                    <TableCell sx={{fontSize:"12px"}} align="left">{row.s_name}</TableCell>
                    <TableCell sx={{fontSize:"12px"}} align="left">{row.s_notes}</TableCell>
                    <TableCell sx={{fontSize:"12px"}} align="right">{row.d_date}</TableCell>
                  </TableRow>
                );
              })}
            
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150, 200]}
          component="div"
          labelRowsPerPage=""
          labelDisplayedRows={()=> {
            return ''
          }}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            hidden: true,
            sx:{
              display: "none"
            }
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default EnhancedTable;