import React, {useContext, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import imgBkgLogo from '../../img/logos/logo_w.png'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Icons from '../layout/Material/Icons';
import { Typography } from '@mui/material';
import moduleContext from '../../context/modules/moduleContext';
import SectionContext from '../../context/sections/sectionContext';
import { BrowserRouter as Router, useParams, Link } from 'react-router-dom'
import AuthContext from '../../context/authentication/authContext';
import { RutasContext, SideleftContext } from '../../context';
import imgconfig from '../../img/icons/Configuration-Dark-Gray-1.png'
import imgminsideleft from '../../img/icons/Sidebar-Close-Dark-Gray-1.png'
import imghelp from '../../img/icons/Help-Blue-1.png'
import { useHistory} from 'react-router-dom'

const FireNav = styled(List)({
  '& .MuiListItemButton-root': {
    paddingLeft: 24,
    paddingRight: 24,
  },
  '& .MuiListItemIcon-root': {
    minWidth: 0,
    marginRight: 16,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

const drawerWidth = 238;
const min_drawerWidth = 60;
const openedMixin = (minimium, theme, sty, typeTheme) => ({
    background: typeTheme === 0 ? sty.background_primary_light : "#ebebeb",
    color: sty ? sty.color : 'black',
    width: minimium ? min_drawerWidth: drawerWidth,
    flexShrink: 0,
    borderStyle: 'solid !important',
    borderWidth: 'thin !important',
    borderColor: '#ebebeb !important',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
}),
overflowX: 'hidden',
});

const closedMixin = (theme, sty, typeTheme) => ({
    background: typeTheme === 0 ? sty.background_primary_light : sty.background_primary_dark,
    color: sty ? sty.color : 'black',
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const ListItemImage = styled('img')({width: '70%', textAlign: 'center'})
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, sty, minimium }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(minimium, theme, sty),
            '& .MuiDrawer-paper': openedMixin(minimium, theme, sty),
        }),
        ...(!open && {
            ...closedMixin(theme, sty),
            '& .MuiDrawer-paper': closedMixin(theme, sty),
        }),
    }),
);


const SideLeft = ({sty={}, modulos=[], open, setOpen}) => {
    const [business, setBusiness] = useState(null)
    const [seccionAbierta, setSeccionAbierta] = useState(null)

    const sideleftContext = useContext(SideleftContext);
    const { minimizar, abrir_sideleft, open_menu, abrir_barmenu, modulo_seleccionado } = sideleftContext;

    useEffect(()=>{
        if(modulo_seleccionado == "editar_producto" && minimizar){ abrir_barmenu(true)}
        else{ abrir_barmenu(false)}
    },[minimizar])

    // const rutasContext = useContext(RutasContext);
    // const { getRoute } = rutasContext;

    let history = useHistory();

    const modulesContext = useContext(moduleContext);
    const { moduloActual } = modulesContext;

    const sectionContext = useContext(SectionContext);
    const { sections, seccionesAbiertas, abrirSeccion, seleccionarSeccion} = sectionContext;

    const authContext = useContext(AuthContext);
    const { obtener_negocios, negocios } = authContext;

    const [seccionesFiltradas, setSecciones] = useState([])
    // const [id_negocio, setIdNegocio] = useState(null)

    const changeOpen = async (idOpen=false, id_seccion = null) => {
        setSeccionAbierta(id_seccion)
        document.getElementById("bar_menu_contenedor").style.display = "block";
        seleccionarSeccion(idOpen)
        abrirSeccion(idOpen, id_seccion)
        moduloActual("delete")
    }

    let {section} = useParams()

    useEffect(()=>{
        let id_negocio = localStorage.getItem('id_route');

        if(process.env.REACT_APP_STORE != "Lavteg"){
            setSecciones(sections.filter(sec => sec.i_status > 0))
        }else{
            setSecciones(id_negocio != 0 ? sections.filter(sec => sec.i_status > 0): sections)
        }
        
        const get_business_url = async () => {
            let route = await obtener_negocios(id_negocio)
            console.log(id_negocio)
            console.log(route)
    
            setBusiness(route)
    
            history.push(`/business/${route}`)
    
            if(section){
                if(route != section){
                }
            }else{
                history.push(`/business/${route}`)
            }
        }

        get_business_url()
    },[negocios])

    return (<Box>
        <ThemeProvider
        theme={createTheme({
            components: {
                MuiListItemButton: {
                    defaultProps: {
                        disableTouchRipple: true,
                    },
                },
            },
            palette: {
                mode: 'dark',
                primary: { main: 'rgb(102, 157, 246)' },
                background: { paper: 'rgb(5, 30, 52)' },
            },
        })}
    >
        <Drawer variant="permanent" open={open} sty={sty} minimium={minimizar}>
            <FireNav sx={{overflow: "hidden !important"}}component="nav" disablePadding>
                <ListItemButton component="a" sx={{
                    background: "transparent !important", 
                    height: '9vh', 
                    display: "grid", 
                    justifyItems: "center",
                    maxHeight: "49px !important",
                }}>
                    {open_menu ? null :(<ListItemImage src={imgBkgLogo}/>)}
                </ListItemButton>
                <Box className='scroll-table-component-side-left' sx={{
                    height: '75vh',
                    marginTop: "20px"
                }}>
                    {seccionesFiltradas.length > 0 ? seccionesFiltradas.map((section,i) => {
                        let filter = modulos.filter(modulo => modulo.type === section.type)
                        return(<Box key={i}>
                            <Box sx={{
                                "& span, p, path":{
                                    color: "black !important"                                },
                                padding: "0px !important"
                            }}>
                                <ComponentSection
                                    minimizar={minimizar}
                                    id={i}
                                    id_seccion={section.id}
                                    changeOpen={changeOpen}
                                    section={{...section, view: seccionesAbiertas[`section-${i+1}`]}}
                                    filter={filter}
                                />
                                <Box>
                                    {seccionAbierta == section.id && !minimizar ? (
                                        <SubModulos business={business} section={section} filter={filter}/>
                                    ):null}
                                </Box>
                            </Box>
                        </Box>)
                    }): null}
                </Box>
                <Box sx={{
                    display: "grid",
                    height: "13vh",
                    alignContent: "end"
                }}>
                    <Box sx={{
                        display: "flex",
                        marginX: "13px",
                        paddingY: "4px",
                        borderRadius: "8px",
                        cursor: "pointer",
                        marginY: "5px",
                        "&:hover": {
                            background: "#FAFAFA",
                        }
                    }}>
                        <img src={imgconfig} width="15px" height="15px" style={{marginLeft: "9px"}}/>
                        {!minimizar ? (<Typography 
                            color="#303030" 
                            fontSize={'12px'}
                            fontFamily="semibold"
                            sx={{marginLeft: "7px"}}
                        >Configuración</Typography>):null}
                    </Box>
                    <Box sx={{display: "flex", marginX: "13px", background: "#92BFFF", paddingY: "4px", borderRadius: "8px"}}>
                        <img src={imghelp} width="15px" height="15px" style={{marginLeft: "9px"}}/>
                        {!minimizar ? (<Typography 
                            color="#08306B" 
                            fontSize={'12px'}
                            fontFamily="semibold"
                            sx={{marginLeft: "7px"}}
                        >Ayuda</Typography>):null}
                    </Box>
                    <Box sx={{marginTop: "5px", display: "grid", justifyContent: "right", marginRight: "13px"}}>
                        <img
                            onClick={e=>{
                                abrir_sideleft(!minimizar)
                            }}
                            src={imgminsideleft}
                            width="15px"
                            height="15px"
                            style={{marginLeft: "9px", cursor: "pointer"}}
                        />
                    </Box>
                </Box>
            </FireNav>
        </Drawer>
        
    </ThemeProvider>
    </Box>);
}

const ComponentSection = (props) =>{
    let {
        id,
        changeOpen,
        section = {open: false, name: 'Default', view: false},
        id_seccion=null,
        minimizar
    } = props

    const [s_seleccionada, setSeleccion] = useState(null)

    const authContext = useContext(AuthContext);
    const { usuario, obtener_negocios } = authContext;
    // const rutasContext = useContext(RutasContext);
    // const { getRoute } = rutasContext;

    const sectionContext = useContext(SectionContext);
    const { seccionSeleccionada} = sectionContext;

    let id_route = localStorage.getItem("id_route")
    let route = obtener_negocios(id_route)

    useEffect(()=>{
        setSeleccion(seccionSeleccionada)
    },[seccionSeleccionada])

    return(<Box
        onClick={() => {
            changeOpen(id, id_seccion)
        }}
        className={`sideleft-class ${seccionSeleccionada == id ? "normal-extra_bold": ""}`}
        sx={{
            // bgcolor: seccionSeleccionada == id ? module?.length > 0 ? null: 'white' : null, 
            borderRadius: "8px",
            pl: "10px",
            pt: "5px",
            ml: "10px",
            minHeight: "5px !important",
            maxHeight: "25px !important",
            marginY: "5px",
            "& p, span": {
                // fontFamily: seccionSeleccionada ? seccionSeleccionada == id ? module?.length > 0 ? "'semibold'": "mulish-extra-bold" : "'semibold'": "'semibold'",
                fontFamily: 'semibold',
                "& span, path, p": {color: "black !important"}
            },
            '&:hover': { 
                background: "#f1f1f1",
                "& span, path, p": {color: "black !important"},
            },
        }}
    >
        <Router>
            {/* AQUI VAN LAS RUTAS DE LOS SUBMODULOS */}
            <Link to={`/business/${route? route: "testing"}${section.route.toLowerCase()}`} style={{textDecoration: "none", color: "white"}}>
                <ListItemButton
                    className={`${seccionSeleccionada == id ? "text-extra-bold": ""}`}
                    tabIndex={id}
                    alignItems="flex-start"
                    sx={{
                        "& span, path, p": {color: "black !important"},
                        width: '100% !important',
                        padding: "0px !important",
                        '&:hover': { "& span, path, p": {color: "black !important"} },
                    }}
                >
                    <Box sx={{paddingRight: '15px'}}>
                        <Icons name={section.name}/>
                    </Box>
                    {!minimizar ? (<ListItemText
                        primary={section.name}
                        primaryTypographyProps={{
                            fontSize: "12px",
                            fontWeight: 'medium',
                            lineHeight: '20px',
                            mb: '2px',
                        }}
                        secondaryTypographyProps={{
                            noWrap: true,
                            fontSize: 12,
                            lineHeight: '16px',
                            color: section.view ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
                        }}
                        sx={{ my: 0 }}
                    />):null}
                </ListItemButton>
            </Link>
        </Router>
    </Box>)
}

const SubModulos = ({section, filter = [], business=null}) => {
    const modulesContext = useContext(moduleContext);
    const { moduloActual, module } = modulesContext;

    const sectionContext = useContext(SectionContext);
    const { id_seccion} = sectionContext;

    const seleccionarModulo = async id => {
        moduloActual(id);
        document.getElementById("bar_menu_contenedor").style.display = "block";
    }
    
    return(<Box>
        {filter.length > 0 ? filter.map((m, key_m)=>{
        let styleBkg = module ? module[0]?._id === m?._id ? "white": "transparent": "transparent"
            return(<ListItemButton
                onClick={()=>{
                    seleccionarModulo(m._id)
                    let route_submodule = m.name == "Sucursales" ? "selling_locations": 
                        m.name == "Inventario" ? "inventory": 
                        m.name == "Productos" ? "products":
                        m.name == "Mercados" ? "markets":
                    ""
                    let urlPath = `/business/${business}${section.route}/${route_submodule}`
                    window.history.pushState(`${business}`, 'Title', urlPath);
                }}
                key={key_m}
                sx={{ 
                    ml: "8px",
                    borderRadius: "6px",
                    py: 0, minHeight: 32,
                    color: 'rgba(255,255,255,.8)',
                    background: 
                        key_m == 0 && module.length == 0 ?
                            id_seccion == section.id ? "white": styleBkg:
                            styleBkg,
                    minHeight: "25px !important",
                    maxHeight: "25px !important",
                    '&:hover': { 
                        background: "#f1f1f1",
                        "& span, path, p": {
                            color: "black !important"
                        },
                    }

                }}
            >
                <ListItemText
                    key={`k_${key_m}`}
                    primary={m.name}
                    primaryTypographyProps={{
                        fontSize: "12px",
                        paddingLeft: '20px',
                        fontFamily: module ? module[0]?._id === m?._id ? "mulish-extra-bold" : "'semibold'": "'semibold'",
                    }}
                />
            </ListItemButton>)
        }):null}

    </Box>)
}

export default SideLeft;